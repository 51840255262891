import * as React from "react"
import Layout from "../components/Layout"
import ListReviews from "../components/ListReviews"
import Seo from "../components/Seo/Seo"

// markup
const Reviews = () => {

  return (
    <Layout>

      <Seo
        title={null}
        description={null}
        image={null}
        pathname={'/reviews'}
      />

      <main>
        <ListReviews className="bg-zone-3 py-12 px-4 xl:px-0" container={true} />
      </main>
    </Layout>
  )
}

export default Reviews
