import React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const ItemPost = ({ link = "/", image = null, title = "Lorem ipsum", excerpt = null, date = null, btnLabel = "View" }) => {
    let htmlImage = ''

    if (image) {
        const the_image = getImage(image)
        htmlImage = <Link to={link} className="w-full overflow-hidden">
            <GatsbyImage
                image={the_image}
                alt={image.alt}
                className="block transform duration-300 hover:scale-125"
            />
        </Link>
    } else {
        htmlImage = <Link to={link} className="w-full overflow-hidden">
            <StaticImage
                src={"../images/test1.jpg"}
                placeholder="blurred"
                layout="fullWidth"
                alt="MattressZone"
                className="block transform duration-300 hover:scale-125"
            />
        </Link>
    }

    return (
        <article className="flex flex-wrap flex-col bg-zone-1">
            {htmlImage}
            <div className="flex flex-col justify-between p-4 flex-1 border border-zone-5">
                <h3 className="font-grotesk text-2xl mb-4 font-semibold text-zone-1">
                    <Link to={link} className="hover:underline">{title}</Link>
                </h3>
                <div className="flex justify-between items-center">
                    {date ? <p className="text-zone-3">{new Date(date).toDateString()}</p> : null}
                    <Link to={link} className="float-right py-2 px-4 rounded duration-300 bg-zone-7 border border-zone-4 text-zone-1 hover:bg-zone-9 hover:text-white">{btnLabel}</Link>
                </div>
            </div>
        </article>
    )
}

export default ItemPost
